
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nicosphere-front-end-primary: mat.define-palette(mat.$indigo-palette);
$nicosphere-front-end-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nicosphere-front-end-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nicosphere-front-end-theme: mat.define-light-theme((
  color: (
    primary: $nicosphere-front-end-primary,
    accent: $nicosphere-front-end-accent,
    warn: $nicosphere-front-end-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nicosphere-front-end-theme);


/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: Helvetica;
    src: url(assets/fonts/HelveticaNowDisplay.ttf);
}

@import '@ctrl/ngx-emoji-mart/picker';

body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
html, body { min-height: 100% !important; height: 100%; }
html{
  overflow: hidden;
}
body { 
      margin: 0;
      font-family: Helvetica !important;
      .root-content{
        height: 100%;
        width: 100%;
      }

}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
  .modal-backdrop{
    z-index: 5;
  }
  .introjs-tooltip{
    width: 400px;
    .introjs-tooltip-header{
      .introjs-tooltip-title{
        margin-top: 30px;
        color: #003F5A;
        font-size: 16px;
        font-weight: 700;
      }
      .introjs-skipbutton{
        color: #005F86;
        text-align: right;
        font-size: 12px;
        font-style: italic;
        font-weight: 300;
        text-decoration-line: underline;
        top: 10px;
        right: 20px;
        display: inline-block;
        width: auto;
      }
    }
    .introjs-tooltiptext{
      .ul-list{
        margin-top: 10px;
        margin-bottom: 0;
      }
      .link{
        color: var(--Color-Nuances-blue-darker, #005F86);
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.8px;
        text-decoration-line: underline;
      }
    }
    .introjs-tooltipbuttons{
      border-radius: 0px 0px 10px 10px;
      background: #FFE9E0;
      text-align: center;
      .counter{
        font-size: 16px;
        font-weight: 500;
      }
      .hide-btn{
        display: none;
      }
      .introjs-button{
        border-radius: 4px;
        background: var(--Color-Brand-orange, #FE612A);
        color: #fff;
        font-size: 10px;
        padding: 5px 10px;
        border: 0;
        text-shadow: none;
        i{
          vertical-align: middle;
        }
      }      
      .last-one{
        background: none;
        border: none;
        color: var(--Color-Brand-orange, #FE612A);
        font-size: 18px;
        padding-bottom: 0;
        padding-top: 0;
        &:focus {
          outline: none;
        }
      }
    }

  }
  .top-z-index{
    z-index: 9999999;
  }
 }

 @media (max-width:767px) { 
  .root-content{
      display: inline-table;
  }
}
